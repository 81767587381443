import { configureStore, combineReducers } from "@reduxjs/toolkit";
import archiveSlice from "./slices/archiveSlice.js";
import commentsSlice from "./slices/commentsSlice.js";
import companiesSlice from "./slices/companiesSlice.js";
import credentialsReducer from "./slices/credentialsSlice.js";
import labelsSlice from "./slices/labelsSlice.js";
import masterplanSlice from "./slices/masterplanSlice.js";
import miscSlice from "./slices/miscSlice.js";
import mySubtasksSlice from "./slices/mySubtasksSlice.js";
import notificationsSlice from "./slices/notificationsSlice.js";
import notesSlice from "./slices/notesSlice.js";
import operatorsSlice from "./slices/operatorsSlice.js";
import profileSlice from "./slices/profileSlice.js";
import rolesSlice from "./slices/rolesSlice.js";
import servicepageSlice from "./slices/servicepageSlice.js";
import servicesSlice from "./slices/servicesSlice.js";
import snackbarSlice from "./slices/snackbarSlice.js";
import statsSlice from "./slices/statsSlice.js";
import subtaskPageSlice from "./slices/subtaskpageSlice.js";
import subtasksTableSlice from "./slices/subtasksTableSlice.js";
import taskboardSlice from "./slices/taskboardSlice.js";
import taskpageSlice from "./slices/taskpageSlice.js";
import teamsSlice from "./slices/teamsSlice.js";
import teamSubtasksSlice from "./slices/teamSubtasksSlice.js";
import uiSlice from "./slices/uiSlice.js";
import userAuthReducer from "./slices/userAuth";

const appReducer = combineReducers({
  archive: archiveSlice,
  comments: commentsSlice,
  companies: companiesSlice,
  credentials: credentialsReducer,
  labels: labelsSlice,
  masterplan: masterplanSlice,
  misc: miscSlice,
  mySubtasks: mySubtasksSlice,
  notifications: notificationsSlice,
  notes: notesSlice,
  operators: operatorsSlice,
  profile: profileSlice,
  roles: rolesSlice,
  servicepage: servicepageSlice,
  services: servicesSlice,
  snackbar: snackbarSlice,
  stats: statsSlice,
  subtaskpage: subtaskPageSlice,
  subtasksTable: subtasksTableSlice,
  taskboard: taskboardSlice,
  taskpage: taskpageSlice,
  teams: teamsSlice,
  teamSubtasks: teamSubtasksSlice,
  ui: uiSlice,
  userAuth: userAuthReducer,
});

const rootReducer = (state, action) => {
  if (action.type === "LOGOUT") {
    state = undefined;
  }
  return appReducer(state, action);
};

export default configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
  devTools: true,
});
