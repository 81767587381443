import { createSlice } from "@reduxjs/toolkit";

export const notesSlice = createSlice({
  name: "notes",
  initialState: {
    notes: [],
    selectedNote: null,
    nextPage: null,
    prevPage: null,
    count: null,
  },
  reducers: {
    setNotesResult: (state, action) => {
      state.notes = action.payload.data;
      state.next = action.payload.pagination.next;
      state.previous = action.payload.pagination.previus;
      state.count = action.payload.pagination.count;
    },
    setNotes: (state, action) => {
      state.notes = action.payload;
    },
    clearNotes: (state) => {
      state.notes = [];
    },
    setSelectedNote: (state, action) => {
      state.selectedNote = action.payload;
    },
    clearSelectedNote: (state) => {
      state.selectedNote = null;
    },
    updateNoteName: (state, action) => {
      if (state.selectedNote) {
        state.selectedNote.name = action.payload;
      }
    },
    updateNoteContent: (state, action) => {
      if (state.selectedNote) {
        state.selectedNote.notes = action.payload;
      }
    },
    updateNotePrivacy: (state, action) => {
      if (state.selectedNote) {
        state.selectedNote.is_private = action.payload;
      }
    },
    updateNoteAttachments: (state, action) => {
      if (state.selectedNote) {
        state.selectedNote.attachments = action.payload;
      }
    },
    addNoteAttachment: (state, action) => {
      if (state.selectedNote) {
        state.selectedNote.attachments.push(action.payload);
      }
    },
    removeNoteAttachment: (state, action) => {
      if (state.selectedNote) {
        state.selectedNote.attachments = state.selectedNote.attachments.filter(
          (attachment) => attachment !== action.payload
        );
      }
    },
    reduceRowCount: (state) => {
      state.count = state.count - 1;
    },
    increaseRowCount: (state) => {
      state.count = state.count + 1;
    },
  },
});

export const {
  setNotesResult,
  setNotes,
  clearNotes,
  setSelectedNote,
  clearSelectedNote,
  updateNoteName,
  updateNoteContent,
  updateNotePrivacy,
  updateNoteAttachments,
  addNoteAttachment,
  removeNoteAttachment,
  reduceRowCount,
  increaseRowCount,
} = notesSlice.actions;

export default notesSlice.reducer;
