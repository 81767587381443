import { createSlice } from "@reduxjs/toolkit";

export const subtasksTableSlice = createSlice({
  name: "subtasksTable",
  initialState: {
      task: null,
      subtasks: [],
      deletedSubtaskIds: [],
      nextPage: null,
      prevPage: null,
      count: null,
    },
  reducers: {
    setSubtasksTableResult: (state, action) => {
      state.subtasks = action.payload.data;
      state.next = action.payload.pagination.next;
      state.previous = action.payload.pagination.previus;
      state.count = action.payload.pagination.count;
    },
    setTask: (state, action) => {
      state.task = action.payload;
    },
    setSubtasks: (state, action) => {
      state.subtasks = action.payload;
    },
    setAssignedOperators: (state, action) => {
      state.task.operators = action.payload;
    },
    clearAssignedOperators: (state) => {
      state.task.operators = [];
    },
    clearAll: (state) => {
      state.task = null;
      state.subtasks = [];
      state.deletedSubtaskIds = [];
      state.nextPage = null;
      state.prevPage = null;
      state.count = null;
    },
    setAssignedOperatorsOfSpecificSubTask: (state, action) => {
      state.subtasks = state.subtasks.map((subtask) => {
        if (subtask.id === action.payload.id) {
          return {
            ...subtask,
            operators: action.payload.operators,
          };
        }
        return subtask;
      });
    },
    addDeletedSubtaskIds: (state, action) => {
      state.deletedSubtaskIds = [...state.deletedSubtaskIds, action.payload];
    },
  },
});

export const {
  setSubtasksTableResult,
  setTask,
  setSubtasks,
  setAssignedOperators,
  clearAssignedOperators,
  clearAll,
  setAssignedOperatorsOfSpecificSubTask,
  addDeletedSubtaskIds,
} = subtasksTableSlice.actions;
export default subtasksTableSlice.reducer;
