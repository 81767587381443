import React from "react";
import { Typography, Box, Button, IconButton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import genericErrorImage from "../../assets/img/generic-error.png";

const ErrorFallback = ({ error, resetErrorBoundary }) => {
  const navigate = useNavigate();

  const handleRetry = () => {
    resetErrorBoundary();
    window.location.reload();
  };

  const handleGoHome = () => {
    navigate("/");
    handleRetry();
  };

  const handleCopyError = () => {
    navigator.clipboard.writeText(`Errore: \n${error.message}`);
    alert("Error message copied to clipboard!");
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      minHeight="100vh"
      textAlign="center"
      padding={3}
    >
      <img
        src={genericErrorImage}
        alt="Error 404"
        style={{ width: "auto", height: "300px" }}
      />
      <Typography variant="h3" color="initial" gutterBottom>
        Error :(
      </Typography>
      <Typography variant="h4" component="h1" gutterBottom>
        Oops! Something went wrong.
      </Typography>
      <Box position="relative" display="inline-block">
        <textarea
          readOnly
          value={`Errore: \n${error.message}`}
          rows={10}
          cols={50}
          style={{
            margin: "10px",
            border: "1px solid black",
            padding: "10px",
            borderRadius: "10px",
            color: "red",
          }}
        />
        <IconButton
          onClick={handleCopyError}
          sx={{
            position: "absolute",
            top: "5px",
            right: "5px",
            backgroundColor: "white",
            padding: "5px",
          }}
        >
          <ContentCopyIcon />
        </IconButton>
      </Box>
      <Box mt={2}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleRetry}
          sx={{ mr: 2 }}
        >
          Retry
        </Button>
        <Button variant="outlined" color="secondary" onClick={handleGoHome}>
          Go to Home
        </Button>
      </Box>
    </Box>
  );
};

export default ErrorFallback;
