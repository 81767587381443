import { createSlice } from "@reduxjs/toolkit";

export const subtaskPageSlice = createSlice({
  name: "subtaskpage",
  initialState: {
    subtask: null,
    activities: [],
  },
  reducers: {
    setSubtask: (state, action) => {
      state.subtask = action.payload;
    },
    setActivities: (state, action) => {
      state.activities = action.payload;
    },
    setAssignedOperators: (state, action) => {
      state.subtask.operators = action.payload;
    },
    clearAssignedOperators: (state) => {
      state.subtask.operators = [];
    },
    clearAll: (state) => {
      state.subtask = null;
      state.activities = [];
    },
    updateActivity: (state, action) => {
      const updatedActivity = action.payload;
      state.activities = state.activities.map((activity) =>
        activity.id === updatedActivity.id ? updatedActivity : activity
      );
    },
    addAttachmentToActivity: (state, action) => {
      // add new attachment to activity
      if (state.activities) {
        state.activities = state.activities.map((activity) => {
          if (activity.id === action.payload.id) {
            return {
              ...activity,
              attachments: [...activity.attachments, action.payload.attachment],
            };
          }
          return activity;
        });
      }
    },
    removeAttachmentFromActivity: (state, action) => {
      // remove attachment from activity
      if (state.activities) {
        state.activities = state.activities.map((activity) => {
          if (activity.id === action.payload.id) {
            return {
              ...activity,
              attachments: activity.attachments.filter(
                (attachment) => attachment.id !== action.payload.attachment.id
              ),
            };
          }
          return activity;
        });
      }
    },
  },
});

export const {
  setSubtask,
  setActivities,
  setAssignedOperators,
  clearAssignedOperators,
  clearAll,
  updateActivity,
  addAttachmentToActivity,
  removeAttachmentFromActivity,
} = subtaskPageSlice.actions;
export default subtaskPageSlice.reducer;
