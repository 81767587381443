import { createSlice } from "@reduxjs/toolkit";

export const archiveSlice = createSlice({
  name: "archive",
  initialState: {
    archive: [],
    services: [],
    tasks: [],
    subtasks: [],
    contentHandlers: [],
    selectedHandler: null,
    nextTasks: null,
    previousTasks: null,
    countTasks: null,
    nextSubtasks: null,
    previousSubtasks: null,
    countSubtasks: null,
    rows: [],
  },
  reducers: {
    setArchiveResult: (state, action) => {
      state.archive = action.payload.data;
      state.services = action.payload?.data?.services;
      state.tasks = action.payload?.data?.tasks;
      state.subtasks = action.payload?.data?.subtasks;

      state.nextTasks = action.payload?.data?.tasks.pagination?.next;
      state.previousTasks = action.payload?.data?.tasks.pagination?.previus;
      state.countTasks = action.payload?.data?.tasks.pagination?.count;

      state.nextSubtasks = action.payload?.data?.subtasks?.pagination?.next;
      state.previousSubtasks =
        action.payload?.data?.subtasks?.pagination?.previus;
      state.countSubtasks = action.payload?.data?.subtasks?.pagination?.count;
    },
    setArchive: (state, action) => {
      state.archive = action.payload;
      state.services = action.payload.services;
      state.tasks = action.payload.tasks;
      state.subtasks = action.payload.subtasks;
    },
    clearArchive: (state) => {
      state.archive = [];
    },
    setContentHandlers: (state, action) => {
      state.contentHandlers = action.payload;
    },
    setSelectedHandler: (state, action) => {
      state.selectedHandler = action.payload;
    },
    clearSelectedHandler: (state) => {
      state.selectedHandler = null;
    },
    decreaseCountTasks: (state) => {
      state.countTasks = state.countTasks - 1;
    },
    decreaseCountSubtasks: (state) => {
      state.countSubtasks = state.countSubtasks - 1;
    },
    setRows: (state, action) => {
      state.rows = action.payload;
    },
    setTasks: (state, action) => {
      state.tasks = action.payload;
    },
    setSubtasks: (state, action) => {
      state.subtasks = action.payload;
    },
    setServices: (state, action) => {
      state.services = action.payload;
    },
  },
});

export const {
  setArchiveResult,
  setArchive,
  clearArchive,
  setContentHandlers,
  setSelectedHandler,
  clearSelectedHandler,
  decreaseCountTasks,
  decreaseCountSubtasks,
  setRows,
  setTasks,
  setSubtasks,
  setServices,
} = archiveSlice.actions;

export default archiveSlice.reducer;
