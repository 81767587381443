import { createSlice } from "@reduxjs/toolkit";

export const miscSlice = createSlice({
  name: "misc",
  initialState: {
    colors: [],
    selectedColor: {
      bgcolor: "#FFFFFF",
      color: "#000000",
      name: "White",
    },
  },
  reducers: {
    setColors: (state, action) => {
      state.colors = action.payload;
    },
    setSelectedColor: (state, action) => {
      state.selectedColor = action.payload;
    },
    clearAll: (state) => {
      state.colors = [];
      state.selectedColor = null;
    },
  },
});

export const { setColors, setSelectedColor, clearAll } = miscSlice.actions;

export default miscSlice.reducer;
