import { createSlice } from "@reduxjs/toolkit";

export const labelsSlice = createSlice({
  name: "labels",
  initialState: {
    labels: [],
    selectedLabels: [],
  },
  reducers: {
    setLabels: (state, action) => {
      state.labels = action.payload;
    },
    clearLabels: (state) => {
      state.labels = [];
    },
    setSelectedLabels: (state, action) => {
      state.selectedLabels = action.payload;
    },
    clearSelectedLabels: (state) => {
      state.selectedLabels = [];
    },
  },
});

export const {
  setLabels,
  clearLabels,
  setSelectedLabels,
  clearSelectedLabels,
} = labelsSlice.actions;

export default labelsSlice.reducer;
