import React, { Suspense } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";

// MUI
import { Backdrop, CircularProgress } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { LicenseInfo } from "@mui/x-license";

// Utils
import { theme } from "./themes.js";
import { ErrorBoundary } from "react-error-boundary";

// Components
import PublicRoute from "./components/PublicRoute.jsx";
import ProtectedRoute from "./components/ProtectedRoute.jsx";
import NotificationSnackbar from "./components/NotificationSnackbar.jsx";

// Error Pages
import ErrorFallback from "./routes/ErrorPages/ErrorFallback";
const Error403 = React.lazy(() => import("./routes/ErrorPages/Error403.jsx"));
const Error404 = React.lazy(() => import("./routes/ErrorPages/Error404.jsx"));
const ComingSoon = React.lazy(() =>
  import("./routes/ErrorPages/ComingSoon.jsx")
);

// Routes
const Dashboard = React.lazy(() => import("./routes/Dashboard/Dashboard.jsx"));
const Taskboard = React.lazy(() => import("./routes/Taskboard"));
const TasksTable = React.lazy(() =>
  import("./routes/TasksTableByCompany/TasksTableByCompany.jsx")
);
const CompanyInformation = React.lazy(() =>
  import("./routes/CompanyInformation")
);
const Notes = React.lazy(() => import("./routes/NotesPage"));
const Login = React.lazy(() => import("./routes/Authentication/Login"));
const Signup = React.lazy(() => import("./routes/Authentication/Signup"));
const CompleteRegistration = React.lazy(() =>
  import("./routes/Authentication/CompleteRegistration")
);
const ServicePage = React.lazy(() => import("./routes/ServicePage.jsx"));
const TaskPage = React.lazy(() => import("./routes/TaskPage.jsx"));
const SubTaskPage = React.lazy(() => import("./routes/SubTaskPage.jsx"));
const Profile = React.lazy(() => import("./routes/Profile/Profile.jsx"));
const AccountVerification = React.lazy(() =>
  import("./routes/Authentication/AccountVerification")
);
const NotificationPage = React.lazy(() =>
  import("./routes/NotificationPage/NotificationPage.jsx")
);
const ResetPassword = React.lazy(() =>
  import("./routes/ResetPassword/ResetPassword.jsx")
);
const ForgotPassword = React.lazy(() => import("./routes/ForgotPassword"));
const ArchivePage = React.lazy(() =>
  import("./routes/ArchivePage/ArchivePage.jsx")
);
const ChangePassword = React.lazy(() => import("./routes/ChangePassword"));
const VideoTutorials = React.lazy(() => import("./routes/VideoTutorials.jsx"));

// Management Routes
const RolesManagementPage = React.lazy(() =>
  import("./routes/RolesManagementPage/RolesManagementPage.jsx")
);
const ClientsManagementPage = React.lazy(() =>
  import("./routes/ClientsManagementPage.jsx")
);
const ManageClient = React.lazy(() => import("./routes/ManageClient.jsx"));
const OperatorsManagementPage = React.lazy(() =>
  import("./routes/OperatorsManagementPage/OperatorsManagementPage.jsx")
);
const ManagementHomePage = React.lazy(() =>
  import("./routes/ManagementHomePage/ManagementHomePage.jsx")
);
const TeamsManagementPage = React.lazy(() =>
  import("./routes/TeamManagementPage/TeamsManagementPage.jsx")
);
const TeamDetailsManagementPage = React.lazy(() =>
  import("./routes/TeamManagementPage/TeamDetailsManagementPage.jsx")
);
const MasterPlanManagementPage = React.lazy(() =>
  import("./routes/MasterPlanManagementPage/MasterPlanManagementPage.jsx")
);
const MasterPlanDetails = React.lazy(() =>
  import("./routes/MasterPlanManagementPage/MasterPlanDetails.jsx")
);

// Redux
import { useDispatch } from "react-redux";
import {
  setIsAuth,
  setRefresh,
  setAccess,
  setUserType,
  setOperatorId,
  setSubdomain,
} from "./redux/slices/userAuth.js";

LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_LICENSE);

function Routing() {
  return (
    <Router>
      <Suspense
        fallback={
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={true}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        }
      >
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          <Routes>
            <Route
              path="/login"
              element={
                <PublicRoute>
                  <Login />
                </PublicRoute>
              }
            />
            <Route
              path="/signup/:token"
              element={
                <PublicRoute>
                  <Signup />
                </PublicRoute>
              }
            />
            <Route
              path="/reset-password/:token"
              element={
                <PublicRoute>
                  <ResetPassword />
                </PublicRoute>
              }
            />
            <Route
              path="/forgot-password"
              element={
                <PublicRoute>
                  <ForgotPassword />
                </PublicRoute>
              }
            />
            <Route
              path="/account-verification/:otp_id"
              element={
                <PublicRoute>
                  <AccountVerification />
                </PublicRoute>
              }
            />
            <Route
              path="/complete-registration/:user_id"
              element={
                <PublicRoute>
                  <CompleteRegistration />
                </PublicRoute>
              }
            />
            <Route
              path="/profile"
              element={
                <ProtectedRoute>
                  <Profile />
                </ProtectedRoute>
              }
            />
            <Route
              path="/profile/:user_id"
              element={
                <ProtectedRoute>
                  <Profile />
                </ProtectedRoute>
              }
            />
            <Route
              path="/dashboard"
              element={
                <ProtectedRoute>
                  <Dashboard />
                </ProtectedRoute>
              }
            />
            <Route
              path="/:company_id/taskboard"
              element={
                <ProtectedRoute>
                  <Taskboard />
                </ProtectedRoute>
              }
            />
            <Route
              path="/:company_id/taskstable"
              element={
                <ProtectedRoute>
                  <TasksTable />
                </ProtectedRoute>
              }
            />
            <Route
              path="/:company_id/information"
              element={
                <ProtectedRoute>
                  <CompanyInformation />
                </ProtectedRoute>
              }
            />
            <Route
              path="/:company_id/notes"
              element={
                <ProtectedRoute>
                  <Notes />
                </ProtectedRoute>
              }
            />
            <Route
              path="/:company_id/notes/:note_id"
              element={
                <ProtectedRoute>
                  <Notes isShowNote={true} />
                </ProtectedRoute>
              }
            />
            <Route
              path="/:company_id/notes/new-note"
              element={
                <ProtectedRoute>
                  <Notes isNewNote={true} />
                </ProtectedRoute>
              }
            />
            <Route
              path="/service/:company_id/:id"
              element={
                <ProtectedRoute>
                  <ServicePage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/task/:company_id/:id"
              element={
                <ProtectedRoute>
                  <TaskPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/subtask/:company_id/:id"
              element={
                <ProtectedRoute>
                  <SubTaskPage />
                </ProtectedRoute>
              }
            />
            {/* Management Routes */}
            <Route
              path="/management"
              element={
                <ProtectedRoute>
                  <ManagementHomePage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/management/operators"
              element={
                <ProtectedRoute>
                  <OperatorsManagementPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/management/roles"
              element={
                <ProtectedRoute>
                  <RolesManagementPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/management/teams"
              element={
                <ProtectedRoute>
                  <TeamsManagementPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/management/clients/:company_id"
              element={
                <ProtectedRoute>
                  <ManageClient />
                </ProtectedRoute>
              }
            />
            <Route
              path="/management/clients"
              element={
                <ProtectedRoute>
                  <ClientsManagementPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/management/teams/:team_id"
              element={
                <ProtectedRoute>
                  <TeamDetailsManagementPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/management/taskboard-configuration"
              element={
                <ProtectedRoute>
                  <MasterPlanManagementPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/management/taskboard-configuration/details/:service_id"
              element={
                <ProtectedRoute>
                  <MasterPlanDetails />
                </ProtectedRoute>
              }
            />
            <Route
              path="/management/taskboard-configuration/details/:service_id/:subservice_id"
              element={
                <ProtectedRoute>
                  <MasterPlanDetails />
                </ProtectedRoute>
              }
            />
            <Route
              path="/change-password"
              element={
                <ProtectedRoute>
                  <ChangePassword />
                </ProtectedRoute>
              }
            />
            <Route
              path="/notifications"
              element={
                <ProtectedRoute>
                  <NotificationPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/:company_id/archive"
              element={
                <ProtectedRoute>
                  <ArchivePage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/video-tutorials"
              element={
                <ProtectedRoute>
                  <VideoTutorials />
                </ProtectedRoute>
              }
            />
            <Route path="/403" element={<Error403 />} />
            <Route path="/coming-soon" element={<ComingSoon />} />
            <Route path="*" element={<Error404 />} />
            <Route path="/" element={<Navigate to="/login" replace />} />
          </Routes>
        </ErrorBoundary>
      </Suspense>
    </Router>
  );
}

function App() {
  const dispatch = useDispatch();
  // Handle login and logout data transfer if required
  const urlParams = new URLSearchParams(window.location.search);

  // Handle login data transfer
  const encodedData = urlParams.get("data");
  if (encodedData) {
    try {
      const data = JSON.parse(decodeURIComponent(encodedData));

      // Save data in the subdomain's localStorage
      localStorage.setItem("refresh", JSON.stringify(data.refresh));
      localStorage.setItem("access", JSON.stringify(data.access));
      localStorage.setItem("user_type", JSON.stringify(data.user_type));
      localStorage.setItem("operator_id", JSON.stringify(data.operator_id));
      localStorage.setItem("subdomain", JSON.stringify(data.subdomain));

      // Remove data from the query string for security reasons
      window.history.replaceState({}, document.title, window.location.pathname);

      // Update Redux state and notify the user
      dispatch(
        setSnackbar({
          message: "Login successfully completed.",
          open: true,
          severity: "success",
        })
      );
    } catch (error) {
      console.error("Failed to decode login data:", error);
      window.location.href = window.location.origin + "/login";
    }
  }

  // Init isAuth & refresh
  const access = localStorage.getItem("access");
  const refresh = localStorage.getItem("refresh");
  const userType = localStorage.getItem("user_type");
  const operatorId = localStorage.getItem("operator_id");
  const subdomain = localStorage.getItem("subdomain");

  React.useEffect(() => {
    if (access) {
      dispatch(setAccess(JSON.parse(access)));
    }
    if (refresh) {
      dispatch(setRefresh(JSON.parse(refresh)));
    }
    if (userType) {
      dispatch(setUserType(JSON.parse(userType)));
    }
    if (operatorId) {
      dispatch(setOperatorId(JSON.parse(operatorId)));
    }
    if (subdomain) {
      dispatch(setSubdomain(subdomain));
    }
    if (!!access) {
      dispatch(setIsAuth());
    }
  }, [dispatch, access, refresh, userType]);

  return (
    <>
      <ThemeProvider theme={theme}>
        <Routing />
        <NotificationSnackbar />
      </ThemeProvider>
    </>
  );
}

export default App;
