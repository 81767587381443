import { createSlice } from "@reduxjs/toolkit";

export const teamSubtasksSlice = createSlice({
  name: "teamSubtasks",
  initialState: {
    subtasks: [],
    // nextPage: null,
    // prevPage: null,
    // count: null,
  },
  reducers: {
    clearAll: (state) => {
      state.subtasks = [];
      //   state.nextPage = null;
      //   state.prevPage = null;
      //   state.count = null;
    },
    setTeamSubtasksResult: (state, action) => {
      //   state.subtasks = action.payload.data;
      //   state.next = action.payload.pagination.next;
      //   state.previous = action.payload.pagination.previus;
      //   state.count = action.payload.pagination.count;
      state.subtasks = action.payload;
    },
  },
});

export default teamSubtasksSlice.reducer;

export const { setTeamSubtasksResult } = teamSubtasksSlice.actions;
